<template>
    <div class="container">
        <footer>
        <p class="bottom">
        </p>
    </footer>
    </div>
</template>

<style lang="less" scoped>
footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    border-top: 1px solid #79234b;
    font-size: 14px;
    padding: 10px 0;
    margin-top: -50px;
    p {
        opacity: .59;
        color: #fff;
        font-family: 'Inter';
    }
}

</style>